<script>
	let map =
		"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.9864026612577!2d-69.910393!3d18.484275099999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf89b5823d4207%3A0x51f74160ca6a86f8!2sAutoCentro%20Rivera!5e0!3m2!1sen!2sus!4v1613689228763!5m2!1sen!2sus";
</script>

<main>
	<h1>Autocentro Rivera</h1>
	<h2>Su Veh&iacute;culo en <strong>Manos Expertas</strong></h2>
	<h1>809.544.3427</h1>

	<div>Calle Profesor Amiama G&oacute;mez #88</div>
	<div>Villa Juana. Santo Domingo. D.N.</div>

	<iframe
		title="embedded google map"
		src="{map}"
		width="100%"
		height="60%"
		frameborder="0"
		style="border:0;"
		allowfullscreen
		aria-hidden="false"
		tabindex="0"></iframe>
</main>

<style>
	main {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 1rem;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		text-align: center;
	}

	h1 {
		text-transform: uppercase;
		margin: 1rem;
	}

	h2 {
		font-weight: 400;
		margin: 0;
	}

	div {
		margin: 0.25rem 1rem;
	}

	iframe {
		flex: auto;
		margin-top: 1rem;
	}
</style>
